
import { mixins, Options, Vue } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { EnquiryCreateModel, OrderCartItemViewModel, StoreStatus, StoreViewModel } from "@/api-client";
import countries from "@/assets/country-codes/countries.json";
import { Carts, CustomizedProducts, Enquiries, Stores } from "@/network/api";
import { store } from "@/store";
import { Country, FormatDate, ScrollTop, UploadPath } from "@/mixins/utilities";
import EnquiryProductTile from "@/components/enquiries/EnquiryProductTile.vue";
import globalAxios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Options({
  components: { Spinner, Form, Field, ErrorMessage, EnquiryProductTile, Datepicker },
  props: {
    containerClass: { default: "col-11 col-md-10 col-xl-8 pt-4" },
    justify: { default: "center" },
    campaignId: { default: "" },
    usePageAsLead: { default: false },
    scrollTopSubmit: { default: false },
    scrollTo: { default: false },
  },
})
export default class EnquiryPage extends mixins(UploadPath, Country, FormatDate, ScrollTop) {
  loading = false;
  currentStatus: number = STATUS_INITIAL;
  uploadedFiles: any;
  uploadError: any;
  enquiryForm: EnquiryCreateModel = {
    countryId: "",
    customerName: "",
    customerEmail: "",
    customerContactNumber: "",
    leadIn: "",
    businessName: "",
    ponumber: "",
    message: "",
    attachments: [],
    customizedProducts: [],
    googleRecaptchaResponse: "",
    cartId: "",
    expectedQuantity: null,
    targetDate: null,
    campaignId: null
  };
  selectedDialCode = "";
  phoneNumber = "";
  countryCodes: any = countries;
  enquiryComplete = false;
  attachments: any = [];
  fileCount = 0;
  customizedProductDetails: Array<any> = [];
  showAllProducts = false;
  recapthcaObject: IReCaptchaComposition | undefined = undefined;
  storeInfo: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };
  usePageAsLead = false;
  campaignId = "";
  scrollTopSubmit = false;
  scrollTo = '';

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  get sortedDialCodes() {
    return this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  get remainingProducts() {
    let total = this.customizedProductDetails.length;
    if (total > 2) {
      return total - 2;
    } else {
      return 0;
    }
  }

  created() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;

    let user = store.getters["user/profile"];

    if (this.$route.query.from) {
      this.enquiryForm.leadIn = this.$route.query.from as string;
    }

    if (this.$route.query.store) {
      this.enquiryForm.storeId = this.$route.query.store as string;
      this.loadStoreInfo();
    }

    if(this.usePageAsLead) {
      let leadIn = window.location.pathname;
      this.enquiryForm.leadIn = leadIn as string;
    }

    if (user) {
      this.enquiryForm.customerEmail = user.email;
      this.enquiryForm.customerName = user.displayName;
      this.enquiryForm.customerContactNumber = user.phoneNumber;
    }

    if (this.enquiryForm.customerContactNumber && this.enquiryForm.customerContactNumber !== "") {
      let array = this.enquiryForm.customerContactNumber.split("-");
      if (array.length >= 2) {
        this.selectedDialCode = array[0];
        array.shift();
        this.phoneNumber = array.join("-");
      }
    }

    if (this.selectedDialCode == "") {
      let selectedCountry = store.getters["location/country"];

      if (selectedCountry) {
        let matchedCountry = this.countryCodes.find((country: any) => {
          return selectedCountry.code === country.isoCode;
        });

        if (matchedCountry) {
          this.selectedDialCode = matchedCountry.dialCode;
        }
      }

      if (!this.selectedDialCode) {
        this.selectedDialCode = "+1";
      }
    }

    if (this.$route.query.design) {
      this.enquiryForm.customizedProducts = [];
      this.enquiryForm.customizedProducts.push({
        customizedProductId: this.$route.query.design as string,
        quantity: Number(this.$route.query.quantity) || 0,
      });
      this.loadCustomizedProduct();
    }

    if (this.$route.query.cart) {
      let cartId = this.$route.query.cart as string;

      if (cartId) {
        this.enquiryForm.cartId = cartId;
      } else {
        this.enquiryForm.cartId = null;
      }

      this.loadCartProducts(cartId);
    }

    if (this.$route.query.name) {
      this.enquiryForm.customerName = this.$route.query.name as string;
    }
  }

  updated() {
    let user = store.getters["user/profile"];

    if (user) {
      this.enquiryForm.customerEmail = user.email;
      this.enquiryForm.customerName = user.displayName;
      this.enquiryForm.customerContactNumber = user.phoneNumber;
    }

    if (this.$route.query.name) {
      this.enquiryForm.customerName = this.$route.query.name as string;
    }
  }

  async recaptcha() {
    if (this.recapthcaObject) {
      this.loading = true;
      // Wait until recaptcha has been loaded.
      await this.recapthcaObject.recaptchaLoaded();

      // Execute reCAPTCHA with action
      const token = await this.recapthcaObject.executeRecaptcha("contact");

      // Do stuff with the received token.
      this.loading = false;
      return token;
    }
  }

  async loadStoreInfo() {
    if(this.enquiryForm.storeId) {
      this.loading = true;
      await Stores.storesIdGet(this.enquiryForm.storeId)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.storeInfo = res.data.resultData as StoreViewModel;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  formatTargetDate() {
    return this.formatDate(this.enquiryForm.targetDate);
  }

  handleNumber({target} :any) {
    if(target.value !== '') {
      if(+target.value <= 0) {
        target.value = target._value = (this.enquiryForm.expectedQuantity as any) = '0';
      } else if (target.value <= 0) {
        target.value = target._value = (this.enquiryForm.expectedQuantity as any) = '0';
      } else {
        target.value = target._value =  this.enquiryForm.expectedQuantity =+Number(target.value).toFixed(0);
      }
    }
  }

  handleAttachmentName(attachment: any) {
    let arr = attachment.split("/");
    return arr[arr.length - 1];
  }

  deleteItem(attachment: any) {
    let refs = this.$refs as any;
    refs.file.value = "";
    this.attachments.splice(this.attachments.indexOf(attachment), 1);
  }

  filesChange(fileList: any) {
    let files = [...fileList];
    if (!files.length) return;

    files.forEach((file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      this.save(formData);
    });
  }

  async save(formData: any) {
    // upload data to the server
    this.currentStatus = STATUS_SAVING;

    await this.upload(formData)
      .then((x: any) => {
        this.attachments.push(x);
        this.currentStatus = STATUS_INITIAL;
      })
      .catch((err: any) => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  removeStore() {
    this.enquiryForm.storeId = null;
    this.storeInfo = {
      id: "",
      startDate: "",
      endDate: "",
      title: "",
      deliveryFeePerCustomer: 0,
      storeStatusId: StoreStatus.Editing,
      countryId: "",
      countryName: "",
      currency: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      },
      referenceNumber: ""
    };
  }

  remove(id: string) {
    if (id === "all" || this.customizedProductDetails.length === 1) {
      this.customizedProductDetails = [];
      this.enquiryForm.customizedProducts = [];
      this.$router.replace({ name: "Enquiries" });
    } else {
      this.customizedProductDetails = this.customizedProductDetails.filter((item: any) => {
        return item.customizedProductId !== id;
      });

      this.enquiryForm.customizedProducts = this.enquiryForm.customizedProducts.filter((item: any) => {
        return item.customizedProductId !== id;
      });
    }
  }

  async submitEnquiry() {
    if(this.scrollTo) {
      this.scrollTop(this.scrollTo)
    } else if(this.scrollTopSubmit) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    
    let recaptchaToken = await this.recaptcha();

    if (recaptchaToken) {
      this.enquiryForm.googleRecaptchaResponse = recaptchaToken;
    }

    this.loading = true;

    this.enquiryForm.customerContactNumber = `${this.selectedDialCode}-${this.phoneNumber}`;

    this.enquiryForm.attachments = [];
    this.attachments.forEach((attachment: any) => {
      this.enquiryForm.attachments.push({ fileUrl: attachment });
    });

    this.enquiryForm.countryId = this.country().id;

    if (!this.enquiryForm.cartId) {
      let cartId = store.getters["checkout/cartId"];

      if (cartId) {
        this.enquiryForm.cartId = cartId;
      } else {
        this.enquiryForm.cartId = null;
      }
    }

    if((this.enquiryForm.expectedQuantity as any) == ''
      || (this.enquiryForm.expectedQuantity as any) == '0'
      || this.enquiryForm.expectedQuantity == 0
      || this.enquiryForm.expectedQuantity == null
      ) {
      this.enquiryForm.expectedQuantity = null;
    } else {
      this.enquiryForm.expectedQuantity = +this.enquiryForm.expectedQuantity;
    }

    if(this.campaignId) {
      this.enquiryForm.campaignId = this.campaignId
    }

    Enquiries.enquiriesCreateEnquiryPost(this.enquiryForm)
      .then((res) => {
        if (res.data.succeeded) {
          this.$notify({ type: "success", text: "Enquiry sent successfully!" });
          window.dataLayer?.push({ event: "enquiry_submitted" }); // GTM Event
          this.enquiryComplete = true;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage });
        });
      });
  }

  async loadCustomizedProduct() {
    if (this.enquiryForm.customizedProducts.length) {
      this.loading = true;
      this.customizedProductDetails = [];
      this.enquiryForm.customizedProducts.forEach(async (customizedProduct: any) => {
        await CustomizedProducts.customizedProductsIdGet(customizedProduct.customizedProductId)
          .then((res) => {
            if (res.data.succeeded) {
              let design = res.data.resultData;
              if (design) {
                if (!design.quantity && this.$route.query.quantity) {
                  design.quantity = Number(this.$route.query.quantity);
                }

                this.customizedProductDetails.push({
                  customizedProductId: design.id,
                  productName: design.name,
                  title: design.title,
                  previewImageUrl: design.previewImageUrl,
                  backPreviewImageUrl: design.backPreviewImageUrl,
                  quantity: design.quantity,
                });

                customizedProduct.quantity = design.quantity;
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            let errors = error.response.data.errors;
            errors.forEach((error: any) => {
              this.$notify({ type: "error", text: error.friendlyMessage });
            });
            console.log(error);
          });
      });

      this.loading = false;
    }
  }

  async loadCartProducts(cartId: string) {
    if (cartId) {
      this.loading = true;
      const accessKey = (this.$route.query.accessKey as string) || undefined;
      await Carts.cartsGetCartGet(cartId, accessKey)
        .then((res) => {
          if (res.data.succeeded) {
            let cartItems = res.data.resultData?.items as Array<OrderCartItemViewModel>;
            this.customizedProductDetails = [];
            this.enquiryForm.customizedProducts = [];

            if (cartItems.length) {
              cartItems?.forEach((cartItem: OrderCartItemViewModel) => {
                let product = cartItem.customizedProduct;

                this.enquiryForm.customizedProducts.push({
                  customizedProductId: product.id,
                  quantity: product.quantity as number,
                });
                this.customizedProductDetails.push({
                  customizedProductId: product.id,
                  productName: product.name,
                  title: product.title,
                  previewImageUrl: product.previewImageUrl,
                  backPreviewImageUrl: product.backPreviewImageUrl,
                  quantity: product.quantity,
                });
              });
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });

      this.loading = false;
    }
  }
}
