
import { OrderCartItemViewModel, ProductAdjustmentTypeViewModel, ProductSizeAttributeValueViewModel, ProductSizeViewModel, ProductStyleViewModel } from "@/api-client";
import { Products } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import Markdown from 'vue3-markdown-it';

@Options({
  components: { Markdown },
  props: {
    sizing: { default: null },
    misc: { default: null },
    product: { default: '' },
    inCart: {default: false }
  },
  emits: ['loaded']
})
export default class SizeGuidelines extends Vue {
  sizing: Array<any> = [];
  currentStyle: ProductStyleViewModel | null = null;
  validStyles: Array<any> = [];
  style: ProductStyleViewModel = {
    name:	'',
    sizeGroups:	[],
    sizes:	[],
    adjustmentTypes:	[],
    adjustments: []
  }
  product: OrderCartItemViewModel= {
    id: '',
    price: 0,
    orderId: '',
    customizedProduct: {
      id: '',
      productId: '',
      productSlug:	'',
      title: '',
      lastModifiedDate: '',
      name:	'',
      code:	'',
      previewImageUrl:	'',
      backPreviewImageUrl:	'',
      canAddToStore: false,
      customerCanUpdate: false,
      adminCanUpdate: false,
      orderReferenceNumber: '',
      enquiryReferenceNumbers: [],
      orderId: '',
      style:	{
        id:	'',
        name:	'',
        code:	'',
        previewImageUrl:	'',
        frontShadowMaskUrl:	'',
        backShadowMaskUrl:	'',
        manufacturerReference:	'',
        factoryId: ''
      },
      design:	{
        id:	'',
        name:	'',
        code:	'',
        imageUrl:	''
      },
      layers:	[],
      items:	[],
      extras: [],
    },
    quantities: []
  };
  miscHtml = '';
  selectedAdjustmentOverride: Array<any> | null = null;

  created() { 
    if (this.sizing && this.sizing.length) {
      this.sizing.map((style: any) => {
        if (style.sizeGroups.length > 0 || style.adjustmentTypes.length) {
          this.validStyles = [...this.validStyles, style];
        }
      });
      if (this.validStyles.length > 0) {
        this.currentStyle = this.validStyles[0];
        this.getDefaultAdjustmentId();      
      }
    } else {
      this.fetchSizeInfo();
    }
  }

  filteredAdjustments(adjustments:any, overrideAdjustmentTypeId:string) {
    return adjustments.filter((adjustment:any) => adjustment.adjustmentTypeId === overrideAdjustmentTypeId);
  }

  getSizeValueByOverride(size:ProductSizeAttributeValueViewModel, groupIndex:any) {
    if(size.overrideValues.length) {
      const adjustmentTypeId = this.selectedAdjustmentOverride?.[groupIndex];
      const matchedOverride = size.overrideValues.find(override => adjustmentTypeId.adjustmentId === override.adjustmentId);
      if(matchedOverride) {
        return matchedOverride.value;
      }
    }
    return size.value
  }

  getDefaultAdjustmentId() {
    this.selectedAdjustmentOverride = null
    if(this.currentStyle?.sizeGroups.length) {
      this.selectedAdjustmentOverride = []
      this.selectedAdjustmentOverride = this.currentStyle.sizeGroups.map((sizeGroup) => {
        let defaultAdjustmentId;

        if(sizeGroup.attributeValueOverrideAdjustmentTypeId) {
          defaultAdjustmentId = this.currentStyle?.adjustments.find(type => type.adjustmentTypeId == sizeGroup.attributeValueOverrideAdjustmentTypeId)?.id
        }
        return ({
          adjustmentId: defaultAdjustmentId || ''
        })
      })
    }
  }

  overrideAdjustmentType(adjustmentTypeId: string) {
    if(this.currentStyle?.adjustmentTypes.length && adjustmentTypeId) {
      return this.currentStyle?.adjustmentTypes.find((type:ProductAdjustmentTypeViewModel) => type.id === adjustmentTypeId)?.name;
    }
    return null;
  }

  async fetchSizeInfo() {
    let hasProp = Object.prototype.hasOwnProperty.call(this.product, "customizedProduct")
    let prod = this.product as any;
    let productId = hasProp ? prod.customizedProduct.productId : prod.productId;
    let styleId = hasProp ? prod.customizedProduct.style.id : prod.styleId;
    if(productId && styleId) {
      await Products.productsGetSizingForStyleGet(productId, styleId)
    .then((res) => {
      console.log(res);
      
      this.currentStyle = res.data.resultData as ProductStyleViewModel;
      this.miscHtml = this.currentStyle?.sizeGuidelines as string;

      this.getDefaultAdjustmentId();

      this.$emit('loaded')
    })
    .catch((error) => {
      console.log(error);
      this.$emit('loaded')
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
    });
    }
    
  }
}
