import axios from "axios";
import * as CliftonAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new CliftonAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API,
});

const Authentication: CliftonAPI.IdentityAuthenticationApi =
  new CliftonAPI.IdentityAuthenticationApi(globalConf);
const Categories: CliftonAPI.CategoriesApi = new CliftonAPI.CategoriesApi(
  globalConf
);
const Files: CliftonAPI.FilesApi = new CliftonAPI.FilesApi(globalConf);
const Products: CliftonAPI.ProductsApi = new CliftonAPI.ProductsApi(globalConf);
const ProductTags: CliftonAPI.ProductTagsApi = new CliftonAPI.ProductTagsApi(globalConf);
const Blogs: CliftonAPI.BlogsApi = new CliftonAPI.BlogsApi(globalConf);
const BlogCategories: CliftonAPI.BlogCategoriesApi = new CliftonAPI.BlogCategoriesApi(globalConf);
const BannerItems: CliftonAPI.BannerItemsApi = new CliftonAPI.BannerItemsApi(globalConf);
const GeneralSettings: CliftonAPI.GeneralSettingsApi = new CliftonAPI.GeneralSettingsApi(globalConf);
const ProductBundles: CliftonAPI.ProductBundlesApi = new CliftonAPI.ProductBundlesApi(globalConf);
const Clients: CliftonAPI.ClientsApi = new CliftonAPI.ClientsApi(globalConf);
const Countries: CliftonAPI.CountriesApi = new CliftonAPI.CountriesApi(globalConf);
const CustomizedProducts: CliftonAPI.CustomizedProductsApi = new CliftonAPI.CustomizedProductsApi(globalConf);
const Carts: CliftonAPI.CartsApi = new CliftonAPI.CartsApi(globalConf);
const CartItems: CliftonAPI.CartItemsApi = new CliftonAPI.CartItemsApi(globalConf);
const ProductBuilder: CliftonAPI.ProductBuilderApi = new CliftonAPI.ProductBuilderApi(globalConf);
const Payments: CliftonAPI.PaymentsApi = new CliftonAPI.PaymentsApi(globalConf);
const Users: CliftonAPI.UsersApi = new CliftonAPI.UsersApi(globalConf);
const UserAddresses: CliftonAPI.UserAddressesApi = new CliftonAPI.UserAddressesApi(globalConf);
const Orders: CliftonAPI.OrdersApi = new CliftonAPI.OrdersApi(globalConf);
const Enquiries: CliftonAPI.EnquiriesApi = new CliftonAPI.EnquiriesApi(globalConf);
const AdminOrders: CliftonAPI.AdminOrdersApi = new CliftonAPI.AdminOrdersApi(globalConf);
const AdminExtras: CliftonAPI.AdminExtrasApi = new CliftonAPI.AdminExtrasApi(globalConf);
const AdminExtraCustomizedProductItemTemplate: CliftonAPI.AdminExtraCustomizedProductItemTemplateApi = new CliftonAPI.AdminExtraCustomizedProductItemTemplateApi(globalConf);
const SEOSettings: CliftonAPI.PageSeoSettingsApi = new CliftonAPI.PageSeoSettingsApi(globalConf);
const Stores: CliftonAPI.StoresApi = new CliftonAPI.StoresApi(globalConf);
const StoreCustomizedProducts: CliftonAPI.StoreCustomizedProductsApi = new CliftonAPI.StoreCustomizedProductsApi(globalConf);
const BannerMessages: CliftonAPI.BannerMessagesApi = new CliftonAPI.BannerMessagesApi(globalConf);
const CarouselItems: CliftonAPI.CarouselItemsApi = new CliftonAPI.CarouselItemsApi(globalConf);
const Campaigns: CliftonAPI.CampaignsApi = new CliftonAPI.CampaignsApi(globalConf);

export { Authentication, Categories, Files, Products, ProductTags, Blogs, BlogCategories, BannerItems, GeneralSettings, ProductBundles, Clients, Countries, CustomizedProducts, Carts, CartItems, ProductBuilder, Payments, Users, UserAddresses, Orders, Enquiries, AdminOrders, SEOSettings, Stores, StoreCustomizedProducts, BannerMessages, AdminExtras, AdminExtraCustomizedProductItemTemplate, CarouselItems, Campaigns };
